import React from 'react';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/privacy" element={<PrivacyPage />} />
        <Route path="/toc" element={<TermsAndConditionsPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Routes>
    </BrowserRouter>
  );
}

const TipButton = () => {
  return (
    <a
      style={{
        margin: 'auto',
        display: 'block',
        width: '140px',
      }}
      href="https://www.buymeacoffee.com/huedaya" className="w-full">
      <img src="https://www.buymeacoffee.com/assets/img/custom_images/orange_img.png" alt="Buy Me A Coffee" />
    </a>
  );
}

const MainPage = () => {
  return (
    <div className="max-w-md text-center">
      <Navbar />
      <video
        style={{
          boxShadow: '0px 3.3px 10px rgba(0, 0, 0, 0.035), 0px 26px 80px rgba(0, 0, 0, 0.07)',
        }}
        className="video-container" autoPlay muted loop>
        <source src="http://cdn-s3.huedaya.com/easy-maps/demo.mp4" type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <br />
      <TipButton />
    </div>
  );
};

const PrivacyPage = () => {
  return (
    <div className="max-w-md mb-4">
      <Navbar />
      <div style={{ marginTop: "20px", maxWidth: 600, margin: 'auto', textAlign: 'center', marginBottom: 20 }}>
        <h1 style={{
          fontSize: 20,
          fontWeight: 700,
          marginBottom: 20,
        }}>Privacy Policy</h1>
        <p>
          Since <a style={{
            color: "#007bff",
          }} href="https://osmfoundation.org/wiki/Privacy_Policy#Why_do_we_store_and_process_personal_data?">OpenStreetMaps</a> is an Open Source project, they're not collecting any data if you're only using the map not contributing new entry like add new places.

          <br />
          And so this plugin do not collect any data.


          <br />
          <br />
          Last Update: 2024-08-28
        </p>
      </div>
      <TipButton />
    </div>
  );
};

const TermsAndConditionsPage = () => {
  return (
    <div className="max-w-md">

      <Navbar />
      <div style={{ marginTop: "20px", maxWidth: 600, margin: 'auto', textAlign: 'center', marginBottom: 20 }}>
        <h1 style={{
          fontSize: 20,
          fontWeight: 700,
          marginBottom: 20,
        }}>Terms &amp; Conditions</h1>
        <p>
          This plugin is free to use.
          <br />
          But if you want to support the development, you can buy me a coffee.
          <br />
          If you found any glitch or bug, let me know, I will fix that immediately.

          <br />
          <br />
          Last Update: 2024-08-28
        </p>
      </div>
      <br />
      <TipButton />
    </div>
  );
};

const SupportPage = () => {
  return (
    <div className="max-w-md">
      <Navbar />
      <div style={{ marginTop: "20px", maxWidth: 600, margin: 'auto', textAlign: 'center', marginBottom: 20 }}>
        <h1 style={{
          fontSize: 20,
          fontWeight: 700,
          marginBottom: 20,
        }}>Support</h1>
        <p>
          If you need any help you can email me at khakim.hudaya@gmail.com
          <br />
          If you found any glitch or bug, let me know, I will fix that immediately.
        </p>
      </div>
      <br />
      <TipButton />
    </div>
  );
};

const Navbar = () => {
  return (
    <div
      className="navbar"
      style={{
        backgroundColor: "#fff",
        padding: 20,
        borderRadius: 10,
        marginTop: 20,
        marginBottom: 40,
        textAlign: 'center',
      }}
    >
      <h1
        className="text-xs font-bold"
        onClick={() => window.location.href = "/"}
        style={{
          fontSize: 40,
          cursor: "pointer",
          fontWeight: 700,
          marginBottom: 20,
        }}
      >Easy Maps</h1>
      <p>
        Embed OpenStreetMaps to Canva Website by simply pick the coordinate point.
        <br />
        OpenStreetMaps is free and open-source online maps similar to Google Maps.
      </p>
      <div style={{
        display: "flex",
        justifyContent: "center",
        marginTop: 20,
        gap: 20, // Adds space between menu items
      }}>
        <a
          href="/privacy"
          style={{
            fontSize: 14,
            padding: "4px 20px",
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >Privacy Policy</a>
        <a
          href="/toc"
          style={{
            fontSize: 14,
            padding: "4px 20px",
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >Terms & Conditions</a>
        <a
          href="/support"
          style={{
            fontSize: 14,
            padding: "4px 20px",
            border: "1px solid #ccc",
            borderRadius: 10,
          }}
        >Support</a>
      </div>
    </div>
  );
};

export default App;
